// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../../styleguide/components/Heading/H1.res.js";
import * as Hooks from "../../../../../libs/Hooks.res.js";
import * as Breadcrumb from "../../../../../styleguide/components/Breadcrumb/Breadcrumb.res.js";
import * as Routes_City from "../../../../../routes/common/Routes_City.res.js";
import * as Routes_State from "../../../../../routes/common/Routes_State.res.js";
import * as Routes_Country from "../../../../../routes/common/Routes_Country.res.js";
import * as Routes_Location from "../../../../../routes/common/Routes_Location.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationShowContentTabs from "./components/tabs/LocationShowContentTabs.res.js";
import * as LocationShowContentMedia from "./components/media/LocationShowContentMedia.res.js";
import * as LocationShowContentMapTab from "./components/map/LocationShowContentMapTab.res.js";
import * as LocationShowContentDetails from "./components/details/LocationShowContentDetails.res.js";
import * as LocationShowContentBrochures from "./components/brochures/LocationShowContentBrochures.res.js";
import * as LocationShowContentFacilities from "./components/facilities/LocationShowContentFacilities.res.js";
import * as LocationShowMainContentScss from "./LocationShowMainContent.scss";
import * as LocationShowContentCertifications from "./components/certifications/LocationShowContentCertifications.res.js";

var css = LocationShowMainContentScss;

var initialState = {
  currentTab: "Details"
};

var tabs = [
  {
    name: "Details",
    tab: "Details"
  },
  {
    name: "Certifications",
    tab: "Certifications"
  },
  {
    name: "Media",
    tab: "Media"
  },
  {
    name: "Brochures",
    tab: "Brochures"
  },
  {
    name: "On Map",
    tab: "Map"
  }
];

function LocationShowMainContent(props) {
  var isMobile = props.isMobile;
  var providerRole = props.providerRole;
  var userRole = props.userRole;
  var countryState = props.countryState;
  var city = props.city;
  var country = props.country;
  var provider = props.provider;
  var $$location = props.location;
  var match = Hooks.useReducer(initialState, (function (_state, action) {
          return {
                  TAG: "Update",
                  _0: {
                    currentTab: action._0
                  }
                };
        }));
  var dispatch = match[1];
  var state = match[0];
  var tmp;
  switch (userRole) {
    case "Admin" :
        tmp = JsxRuntime.jsx(Breadcrumb.make, {
              breadcrumbLinks: [
                {
                  linkName: "Home",
                  linkPath: "/"
                },
                {
                  linkName: "All Data Centers",
                  linkPath: Routes_Location.index
                },
                {
                  linkName: country.name,
                  linkPath: Routes_Country.show(country.slug)
                },
                {
                  linkName: countryState.name,
                  linkPath: Routes_State.show(countryState.slug, country.slug)
                },
                {
                  linkName: city.name,
                  linkPath: Routes_City.show(countryState.slug, country.slug, city.slug)
                },
                {
                  linkName: $$location.name,
                  linkPath: $$location.url
                },
                {
                  linkName: "Edit",
                  linkPath: Routes_Location.Dashboard.edit($$location.id, provider.slug)
                }
              ],
              wrapperContainerClassName: css.breadcrumbs
            });
        break;
    case "Provider" :
        var exit = 0;
        switch (providerRole) {
          case "Owner" :
          case "Manager" :
              exit = 1;
              break;
          case "Member" :
          case "Other" :
              tmp = null;
              break;
          
        }
        if (exit === 1) {
          tmp = JsxRuntime.jsx(Breadcrumb.make, {
                breadcrumbLinks: [
                  {
                    linkName: "Home",
                    linkPath: "/"
                  },
                  {
                    linkName: "All Data Centers",
                    linkPath: Routes_Location.index
                  },
                  {
                    linkName: country.name,
                    linkPath: Routes_Country.show(country.slug)
                  },
                  {
                    linkName: countryState.name,
                    linkPath: Routes_State.show(countryState.slug, country.slug)
                  },
                  {
                    linkName: city.name,
                    linkPath: Routes_City.show(countryState.slug, country.slug, city.slug)
                  },
                  {
                    linkName: $$location.name,
                    linkPath: $$location.url
                  },
                  {
                    linkName: "Edit",
                    linkPath: Routes_Location.Dashboard.edit($$location.id, provider.slug)
                  }
                ],
                wrapperContainerClassName: css.breadcrumbs
              });
        }
        break;
    default:
      tmp = JsxRuntime.jsx(Breadcrumb.make, {
            breadcrumbLinks: [
              {
                linkName: "Home",
                linkPath: "/"
              },
              {
                linkName: "All Data Centers",
                linkPath: Routes_Location.index
              },
              {
                linkName: country.name,
                linkPath: Routes_Country.show(country.slug)
              },
              {
                linkName: countryState.name,
                linkPath: Routes_State.show(countryState.slug, country.slug)
              },
              {
                linkName: city.name,
                linkPath: Routes_City.show(countryState.slug, country.slug, city.slug)
              },
              {
                linkName: $$location.name,
                linkPath: $$location.url
              }
            ],
            wrapperContainerClassName: css.breadcrumbs
          });
  }
  var match$1 = state.currentTab;
  var tmp$1;
  switch (match$1) {
    case "Certifications" :
        tmp$1 = JsxRuntime.jsx(LocationShowContentCertifications.make, {
              provider: provider,
              location: $$location,
              certifications: $$location.certifications,
              userRole: userRole,
              providerRole: providerRole,
              isMobile: isMobile
            });
        break;
    case "Brochures" :
        tmp$1 = JsxRuntime.jsx(LocationShowContentBrochures.make, {
              locationBrochures: props.locationBrochures,
              provider: provider,
              location: $$location,
              userRole: userRole,
              userLoginStatus: props.userLoginStatus,
              setUserData: props.setUserData
            });
        break;
    case "Details" :
        tmp$1 = JsxRuntime.jsx(LocationShowContentDetails.make, {
              location: $$location
            });
        break;
    case "Media" :
        tmp$1 = JsxRuntime.jsx(LocationShowContentMedia.make, {
              images: props.images
            });
        break;
    case "Map" :
        tmp$1 = JsxRuntime.jsx(LocationShowContentMapTab.make, {
              provider: provider,
              location: $$location
            });
        break;
    
  }
  return JsxRuntime.jsxs("div", {
              children: [
                tmp,
                isMobile ? null : JsxRuntime.jsx(H1.make, {
                        id: "locationName",
                        className: css.locationName,
                        children: provider.name + ": " + $$location.name
                      }),
                JsxRuntime.jsx("div", {
                      children: $$location.summary,
                      className: css.contentSummary,
                      id: "contentSummary"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(LocationShowContentFacilities.make, {
                            facilities: $$location.facilities
                          }),
                      className: css.contentFacilities,
                      id: "contentFacilities"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(LocationShowContentTabs.make, {
                            tabs: tabs,
                            currentTab: state.currentTab,
                            onClick: (function (tab) {
                                dispatch({
                                      TAG: "SelectTab",
                                      _0: tab
                                    });
                              })
                          }),
                      className: css.contentTabs,
                      id: "contentTabs"
                    }),
                tmp$1
              ],
              className: css.mainContentContainer
            });
}

var make = LocationShowMainContent;

export {
  css ,
  initialState ,
  tabs ,
  make ,
}
/* css Not a pure module */
