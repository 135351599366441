// extracted by mini-css-extract-plugin
export var column = "LocationShowContactForm__column__LWaCm";
export var field = "LocationShowContactForm__field__VEmLo";
export var fieldInput = "LocationShowContactForm__fieldInput__Bq66n";
export var fieldRow = "LocationShowContactForm__fieldRow__dktMT";
export var flex = "LocationShowContactForm__flex__FSKEx";
export var flexColumn = "LocationShowContactForm__flexColumn__J9wdy";
export var formContainer = "LocationShowContactForm__formContainer__ctmo8";
export var gap1 = "LocationShowContactForm__gap1__TjmJM";
export var gap2 = "LocationShowContactForm__gap2__Bu6cp";
export var gap3 = "LocationShowContactForm__gap3__rR4Ot";
export var gap4 = "LocationShowContactForm__gap4__szulc";
export var gap5 = "LocationShowContactForm__gap5__HFtEe";
export var label = "LocationShowContactForm__label__fR1I4";
export var required = "LocationShowContactForm__required__qZGVG";
export var row = "LocationShowContactForm__row__fhzRP";
export var selectInput = "LocationShowContactForm__selectInput__uT0sA";