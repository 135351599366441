// extracted by mini-css-extract-plugin
export var column = "LocationShowContentBrochures__column__Rr3qu";
export var flex = "LocationShowContentBrochures__flex__BtNIp";
export var flexColumn = "LocationShowContentBrochures__flexColumn__FKxAF";
export var gap1 = "LocationShowContentBrochures__gap1__it__e";
export var gap2 = "LocationShowContentBrochures__gap2__tYU_F";
export var gap3 = "LocationShowContentBrochures__gap3__nzmUp";
export var gap4 = "LocationShowContentBrochures__gap4__GImz_";
export var gap5 = "LocationShowContentBrochures__gap5__aHTbi";
export var locationBrochures = "LocationShowContentBrochures__locationBrochures__rNcSq";
export var locationBrochuresTitle = "LocationShowContentBrochures__locationBrochuresTitle__FG7Ya";
export var noLocationBrochures = "LocationShowContentBrochures__noLocationBrochures__bwv5n";
export var row = "LocationShowContentBrochures__row__HnOju";