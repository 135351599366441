// extracted by mini-css-extract-plugin
export var column = "LocationShowContentTabs__column__KxVjG";
export var flex = "LocationShowContentTabs__flex__sARQU";
export var flexColumn = "LocationShowContentTabs__flexColumn__lU_83";
export var gap1 = "LocationShowContentTabs__gap1__E7lqb";
export var gap2 = "LocationShowContentTabs__gap2__GyykG";
export var gap3 = "LocationShowContentTabs__gap3__cDI3y";
export var gap4 = "LocationShowContentTabs__gap4__tgXBf";
export var gap5 = "LocationShowContentTabs__gap5__A0rxx";
export var row = "LocationShowContentTabs__row__DRm3p";
export var tab = "LocationShowContentTabs__tab__e2xzG";
export var tabActive = "LocationShowContentTabs__tabActive__vTm7R";