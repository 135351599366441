// extracted by mini-css-extract-plugin
export var column = "LocationShowSidebarStat__column__Y7hC2";
export var flex = "LocationShowSidebarStat__flex__L1Tel";
export var flexColumn = "LocationShowSidebarStat__flexColumn__zbZHR";
export var gap1 = "LocationShowSidebarStat__gap1__IX8Vq";
export var gap2 = "LocationShowSidebarStat__gap2__diqOB";
export var gap3 = "LocationShowSidebarStat__gap3__z2swh";
export var gap4 = "LocationShowSidebarStat__gap4__MUef6";
export var gap5 = "LocationShowSidebarStat__gap5__qdxPo";
export var row = "LocationShowSidebarStat__row__D52vg";
export var statContainer = "LocationShowSidebarStat__statContainer__LPgsu";