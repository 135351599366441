// extracted by mini-css-extract-plugin
export var column = "LocationShowRelatedLocations__column__XIEeb";
export var flex = "LocationShowRelatedLocations__flex__sDjcD";
export var flexColumn = "LocationShowRelatedLocations__flexColumn__ssdcI";
export var gap1 = "LocationShowRelatedLocations__gap1__JdKeu";
export var gap2 = "LocationShowRelatedLocations__gap2__EqL85";
export var gap3 = "LocationShowRelatedLocations__gap3__oUwXz";
export var gap4 = "LocationShowRelatedLocations__gap4__L335h";
export var gap5 = "LocationShowRelatedLocations__gap5__n8tVZ";
export var locationsCount = "LocationShowRelatedLocations__locationsCount__ez9eA";
export var relatedLocations = "LocationShowRelatedLocations__relatedLocations__iA_cT";
export var relatedLocationsHeader = "LocationShowRelatedLocations__relatedLocationsHeader__hEAZT";
export var relatedLocationsList = "LocationShowRelatedLocations__relatedLocationsList__iZcpb";
export var relatedLocationsPagination = "LocationShowRelatedLocations__relatedLocationsPagination__I3AgL";
export var relatedLocationsTitle = "LocationShowRelatedLocations__relatedLocationsTitle__UpNmJ";
export var row = "LocationShowRelatedLocations__row__fLR9m";