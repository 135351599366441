// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../../../styleguide/components/Link/A.res.js";
import * as P from "../../../../../../../styleguide/components/Paragraph/P.res.js";
import * as H2 from "../../../../../../../styleguide/components/Heading/H2.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Routes_LocationBrochure from "../../../../../../../routes/common/Routes_LocationBrochure.res.js";
import * as LocationShowContentBrochure from "./components/brochure/LocationShowContentBrochure.res.js";
import * as LocationShowContentBrochuresScss from "./LocationShowContentBrochures.scss";

var css = LocationShowContentBrochuresScss;

function LocationShowContentBrochures(props) {
  var setUserData = props.setUserData;
  var userLoginStatus = props.userLoginStatus;
  var locationBrochures = props.locationBrochures;
  var tmp;
  if (locationBrochures.length !== 0) {
    tmp = Belt_Array.mapWithIndex(locationBrochures, (function (index, brochure) {
            return JsxRuntime.jsx(LocationShowContentBrochure.make, {
                        locationBrochure: brochure,
                        userLoginStatus: userLoginStatus,
                        setUserData: setUserData
                      }, String(index));
          }));
  } else {
    var tmp$1;
    tmp$1 = props.userRole === "Admin" ? JsxRuntime.jsxs(P.make, {
            children: [
              "As an admin, you may add brochures to this location by clicking ",
              JsxRuntime.jsxs(A.make, {
                    href: Routes_LocationBrochure.Dashboard.$$new,
                    className: css.editLocationLink,
                    children: [
                      "here",
                      "."
                    ]
                  })
            ]
          }) : null;
    tmp = JsxRuntime.jsxs("div", {
          children: [
            JsxRuntime.jsx(P.make, {
                  children: props.provider.name + " has not added any brochures for this location. "
                }),
            tmp$1
          ],
          className: css.noBrochures,
          id: "noBrochures"
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H2.make, {
                      id: "locationBrochuresTitle",
                      className: css.locationBrochuresTitle,
                      children: "Brochures: " + props.location.name
                    }),
                tmp
              ],
              className: css.locationBrochures,
              id: "locationBrochures"
            });
}

var make = LocationShowContentBrochures;

export {
  css ,
  make ,
}
/* css Not a pure module */
