// extracted by mini-css-extract-plugin
export var breadcrumbs = "LocationShowMainContent__breadcrumbs__EBywF";
export var column = "LocationShowMainContent__column__PdJZz";
export var contentDetails = "LocationShowMainContent__contentDetails__QnHxl";
export var contentDetailsTitle = "LocationShowMainContent__contentDetailsTitle__TosOI";
export var contentFacilities = "LocationShowMainContent__contentFacilities__O33cW";
export var contentFeatures = "LocationShowMainContent__contentFeatures__LduOi";
export var contentSummary = "LocationShowMainContent__contentSummary__p3GY0";
export var contentTabs = "LocationShowMainContent__contentTabs__lP3jr";
export var flex = "LocationShowMainContent__flex__rlTuS";
export var flexColumn = "LocationShowMainContent__flexColumn__eWoQc";
export var gap1 = "LocationShowMainContent__gap1__dp09h";
export var gap2 = "LocationShowMainContent__gap2__HCTm3";
export var gap3 = "LocationShowMainContent__gap3__NdP9S";
export var gap4 = "LocationShowMainContent__gap4__bSsxX";
export var gap5 = "LocationShowMainContent__gap5__sIs9y";
export var locationName = "LocationShowMainContent__locationName__NLxb4";
export var mainContentContainer = "LocationShowMainContent__mainContentContainer__u_lG0";
export var row = "LocationShowMainContent__row__HWNh5";