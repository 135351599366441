// extracted by mini-css-extract-plugin
export var active = "LocationShowContentFacilities__active__IO6dh";
export var column = "LocationShowContentFacilities__column__FL7eJ";
export var facilityIcon = "LocationShowContentFacilities__facilityIcon__RvrWZ";
export var facilityName = "LocationShowContentFacilities__facilityName__sTcmQ";
export var facilityType = "LocationShowContentFacilities__facilityType__kIwxN";
export var flex = "LocationShowContentFacilities__flex__adQPS";
export var flexColumn = "LocationShowContentFacilities__flexColumn__RF3Vv";
export var gap1 = "LocationShowContentFacilities__gap1__P0Jql";
export var gap2 = "LocationShowContentFacilities__gap2__lKLCP";
export var gap3 = "LocationShowContentFacilities__gap3__UY18d";
export var gap4 = "LocationShowContentFacilities__gap4__P2j5s";
export var gap5 = "LocationShowContentFacilities__gap5__ul0oV";
export var inactive = "LocationShowContentFacilities__inactive__jaSU1";
export var row = "LocationShowContentFacilities__row__wfGXq";