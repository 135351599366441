// extracted by mini-css-extract-plugin
export var column = "LocationShowContentFeatures__column__rtOZ7";
export var featureName = "LocationShowContentFeatures__featureName__piYp3";
export var flex = "LocationShowContentFeatures__flex__JaF0t";
export var flexColumn = "LocationShowContentFeatures__flexColumn__HRTcs";
export var gap1 = "LocationShowContentFeatures__gap1__Vso3X";
export var gap2 = "LocationShowContentFeatures__gap2__yVKpf";
export var gap3 = "LocationShowContentFeatures__gap3__yBdg4";
export var gap4 = "LocationShowContentFeatures__gap4__TBjh3";
export var gap5 = "LocationShowContentFeatures__gap5__RYlSC";
export var locationFeature = "LocationShowContentFeatures__locationFeature__fvsE9";
export var row = "LocationShowContentFeatures__row__Nb_VA";