// extracted by mini-css-extract-plugin
export var certification = "LocationShowContentCertifications__certification__yF4qV";
export var certificationLabelContainer = "LocationShowContentCertifications__certificationLabelContainer__wFCGN";
export var certificationTitle = "LocationShowContentCertifications__certificationTitle__Hh5oR";
export var certificationTooltip = "LocationShowContentCertifications__certificationTooltip__j3J_u";
export var certifications = "LocationShowContentCertifications__certifications__DWfn7";
export var certificationsRow = "LocationShowContentCertifications__certificationsRow__CC_he";
export var certificationsTitle = "LocationShowContentCertifications__certificationsTitle__bp3q4";
export var column = "LocationShowContentCertifications__column__kEUoQ";
export var flex = "LocationShowContentCertifications__flex__BmdPU";
export var flexColumn = "LocationShowContentCertifications__flexColumn__hbcLX";
export var gap1 = "LocationShowContentCertifications__gap1__wxOjK";
export var gap2 = "LocationShowContentCertifications__gap2__bEiHU";
export var gap3 = "LocationShowContentCertifications__gap3__xKvLM";
export var gap4 = "LocationShowContentCertifications__gap4__oQ5jE";
export var gap5 = "LocationShowContentCertifications__gap5__mUI8_";
export var noCertifications = "LocationShowContentCertifications__noCertifications__SRG6O";
export var row = "LocationShowContentCertifications__row__Mri0T";