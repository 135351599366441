// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../../../styleguide/components/Link/A.res.js";
import * as P from "../../../../../../../styleguide/components/Paragraph/P.res.js";
import * as H2 from "../../../../../../../styleguide/components/Heading/H2.res.js";
import * as IconOk from "../../../../../../../styleguide/icons/IconOk.res.js";
import * as Tooltip from "../../../../../../../styleguide/components/Tooltip/Tooltip.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as IconHelpTooltip from "../../../../../../../styleguide/icons/IconHelpTooltip.res.js";
import * as Routes_Location from "../../../../../../../routes/common/Routes_Location.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationShowContentCertificationsScss from "./LocationShowContentCertifications.scss";

var css = LocationShowContentCertificationsScss;

function LocationShowContentCertifications(props) {
  var isMobile = props.isMobile;
  var certifications = props.certifications;
  var $$location = props.location;
  var provider = props.provider;
  var tmp;
  if (certifications.length !== 0) {
    var itemsPerRow = isMobile ? 1 : 3;
    var totalOptions = certifications.length;
    var numOfRows = Caml_int32.div(totalOptions, itemsPerRow);
    var rowRemainder = Caml_int32.mod_(totalOptions, isMobile ? 1 : 3);
    tmp = Belt_Array.mapWithIndex(Belt_Array.range(0, rowRemainder === 0 ? numOfRows - 1 | 0 : numOfRows), (function (index, param) {
            return JsxRuntime.jsx("div", {
                        children: Belt_Array.mapWithIndex(Belt_Array.slice(certifications, Math.imul(index, itemsPerRow), itemsPerRow), (function (cIndex, certification) {
                                return JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(IconOk.make, {
                                                    size: "SM",
                                                    color: "Teal"
                                                  }),
                                              JsxRuntime.jsxs("div", {
                                                    children: [
                                                      certification.abbreviation,
                                                      JsxRuntime.jsx(Tooltip.make, {
                                                            content: JsxRuntime.jsxs("div", {
                                                                  children: [
                                                                    JsxRuntime.jsx("div", {
                                                                          children: certification.title,
                                                                          className: css.certificationTitle
                                                                        }),
                                                                    JsxRuntime.jsx("div", {
                                                                          children: certification.summary
                                                                        })
                                                                  ],
                                                                  className: css.certificationTooltip
                                                                }),
                                                            position: "Right",
                                                            tooltipContainerClass: css.tooltip,
                                                            withoutArrow: true,
                                                            children: JsxRuntime.jsx(IconHelpTooltip.make, {
                                                                  size: "SM"
                                                                })
                                                          })
                                                    ],
                                                    className: css.certificationLabelContainer
                                                  })
                                            ],
                                            className: css.certification,
                                            id: certification.title
                                          }, "certification-" + String(cIndex));
                              })),
                        className: css.certificationsRow
                      }, "certificationsRow-" + String(index));
          }));
  } else {
    var tmp$1;
    switch (props.userRole) {
      case "Admin" :
          tmp$1 = JsxRuntime.jsxs(P.make, {
                children: [
                  "As an admin, you may add certifications to this location by clicking ",
                  JsxRuntime.jsxs(A.make, {
                        href: Routes_Location.Dashboard.edit($$location.id, provider.slug),
                        className: css.editLocationLink,
                        children: [
                          "here",
                          "."
                        ]
                      })
                ]
              });
          break;
      case "Visitor" :
      case "Agent" :
          tmp$1 = null;
          break;
      default:
        var exit = 0;
        switch (props.providerRole) {
          case "Owner" :
          case "Manager" :
              exit = 1;
              break;
          case "Member" :
          case "Other" :
              tmp$1 = null;
              break;
          
        }
        if (exit === 1) {
          tmp$1 = JsxRuntime.jsxs(P.make, {
                children: [
                  "As an admin, you may add certifications to this location by clicking ",
                  JsxRuntime.jsxs(A.make, {
                        href: Routes_Location.Dashboard.edit($$location.id, provider.slug),
                        className: css.editLocationLink,
                        children: [
                          "here",
                          "."
                        ]
                      })
                ]
              });
        }
        
    }
    tmp = JsxRuntime.jsxs("div", {
          children: [
            JsxRuntime.jsx(P.make, {
                  children: provider.name + " has not declared any certifications for this location. "
                }),
            tmp$1
          ],
          className: css.noCertifications,
          id: "noCertifications"
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H2.make, {
                      id: "certificationsTitle",
                      className: css.certificationsTitle,
                      children: "Data Center Certifications: " + $$location.name
                    }),
                tmp
              ],
              className: css.certifications,
              id: "certifications"
            });
}

var make = LocationShowContentCertifications;

export {
  css ,
  make ,
}
/* css Not a pure module */
